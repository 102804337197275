import { css as o } from "@emotion/react";
var l = /* @__PURE__ */ ((e) => (e[e.MobileSmall = 319] = "MobileSmall", e[e.MobileDefault = 374] = "MobileDefault", e[e.DesktopSmall = 767] = "DesktopSmall", e[e.DesktopMedium = 1169] = "DesktopMedium", e[e.DesktopDefault = 1279] = "DesktopDefault", e[e.DesktopLarge = 1439] = "DesktopLarge", e[
  e.GridLarge = 1169
  /* DesktopMedium */
] = "GridLarge", e[e.GridMedium = 999] = "GridMedium", e[e.GridSmall = 599] = "GridSmall", e))(l || {});
const a = (e, d) => o`
  @media (max-width: ${e}px) {
    ${d};
  }
`, p = (e, d, s) => o`
  @media (min-width: ${e + 1}px) and (max-width: ${d}px) {
    ${s};
  }
`, i = (e, d) => o`
  @media (min-width: ${e + 1}px) {
    ${d};
  }
`, r = (e) => o`
  @media (hover: none), (pointer: coarse), (hover: none) and (pointer: coarse), (hover: none) and (pointer: fine) {
    ${e};
  }
`, t = (e) => o`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${e};
    }
  }
`;
export {
  l as B,
  p as b,
  i as g,
  t as h,
  a as o,
  r as t
};
