import { createContext, ReactNode, useContext } from 'react';

import type { EventParamsType } from '@/utils/eventClient';

export type PageContextType = {
  readonly screenName: string;
  readonly params?: EventParamsType;
};

export const PageContext = createContext<PageContextType>({
  screenName: '',
  params: undefined,
});

/**
 *
 * event tracking에 사용되는 page 레벨의 데이터 context
 *
 * @param {PageContextType} value event tracking 에 사용되는 page 레벨의 데이터
 * @param {ReactNode} children 컴포넌트
 */

export const PageContextProvider = ({
  value,
  children,
}: {
  value: PageContextType;
  children: ReactNode;
}): ReactJSX.Element => <PageContext.Provider value={value}>{children}</PageContext.Provider>;

export const usePageContext = (): PageContextType => {
  const pageContext = useContext(PageContext);

  return pageContext;
};
